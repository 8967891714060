import axios from 'axios';
import { Message } from 'element-ui';

axios.interceptors.response.use(
    res => {
        if (res.data.success) {
            return res.data;
        }else{
            Message.error({ message: res.data.message });
            return;
        }
    })

let base = "api/";

// 登录时的 post 请求
// params 默认是 json 格式，需要转换成 form 表单，因为登录请求只接受 form 表单传参
export const postKeyValueRequest = (url, params) => {
    return axios({
        method: "post",
        url: `${base}${url}`,
        data: params,
        header: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export const postRequest = (url, params) => {
    return axios({
        method: "post",
        url: `${base}${url}`,
        data: params,
    })
}

export const putRequest = (url, params) => {
    return axios({
        method: "put",
        url: `${base}${url}`,
        data: params,
    })
}

export const getRequest = (url, params) => {
    return axios({
        method: "get",
        url: `${base}${url}`,
        params: params,
    })
}

export const deleteRequest = (url, params) => {
    return axios({
        method: "delete",
        url: `${base}${url}`,
        data: params,
    })
}
