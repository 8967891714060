import Index from './pages/index.vue'
import Front from './pages/front.vue'
import Sys from './pages/sys.vue'

const routers = [
  {
    path: '/',
    name: 'front',
    component: Front
  },
  {
    path: '/login',
    name: 'login',
    component: Index
  },
  {
    path: '/front',
    name: 'front',
    component: Front
  },
  {
    path: '/sys',
    name: 'sys',
    component: Sys
  }
]

export default routers
