<template>
    <div>
        <div class="content m-auto ql-container ql-snow pl-4 pr-4 sm:pl-4 sm:pr-4 md:pl-4 md:pr-4 lg:pl-20 lg:pr-20 xl:pl-20 xl:pr-20 2xl:pl-20 2xl:pr-20 flex-1">
          <div class="ql-editor overflow-hidden">
            <div v-html="content"></div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getGlobalParam();
  },
  methods: {
    getGlobalParam() {
      this.getRequest("globalParam/value", { key: 'wallet_content' }).then((resp) => {
        if (resp.success) {
          this.content = resp.message
        }
      });
    },
  },
};
</script>
<style>
html {
  background-color: #FFFFFF
}

.ql-container.ql-snow{
  border: none !important;
}

@media (min-width: 1024px) {
  .content {
    width: 1024px;
  }
}
</style>