<template>
  <div class="flex flex-col justify-center h-full lx">
    <div class="rounded-xl pl-20 pr-20 pt-10 pb-10 bg-white">
      <div class="flex-full text-center p-4 text-2xl">管理后台</div>
        <el-form class="p-1" ref="loginForm" :model="userInfo" label-position="left" :rules="rules">
          <el-form-item prop="username" label="账号">
            <el-input class="mb-1" v-model="userInfo.username" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input class="my-1" v-model="userInfo.password" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
        </el-form>
        <el-button class="w-full" type="primary" @click="loading">登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
          { min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    loading() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.postRequest("user/webLogin", this.userInfo).then(
            (resp) => {
              if (resp) {
                window.sessionStorage.setItem("isUserLoggedIn", true);
                this.$router.push("/sys");
              }
            }
          );
        } else {
          this.$message.error("请输入所有字段");
          return false;
        }
      });
    },
  },
};
</script>

<!-- <style>
.login-box {
  padding: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background-color: #eeeded27;
}
</style> -->