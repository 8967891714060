<template>
  <div class="frame-bg h-full">
    <el-container class="h-full w-full">
      <el-main class="flex flex-1 items-center justify-center p-6">
        <Login></Login>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Login from "@/pages/login.vue";

export default {
  components: { Login },
};
</script>

<style>
.frame-bg{
  background: linear-gradient(rgb(0, 157, 219), pink);
}
</style>