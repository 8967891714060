import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routers from "./routers";
import ElementUI from "element-ui"; //引入element
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/tailwind.css"; //引入tailwind
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueRouter);
Vue.use(ElementUI);

const router = new VueRouter({
  mode: "history",
  routes: routers,
});

Vue.config.productionTip = false;
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
// 全局前置守卫，用于未登录拦截
router.beforeEach((to, from, next) => {
  // 假设我们通过某种方式检查用户是否登录
  
  let isUserLoggedIn = window.sessionStorage.getItem("isUserLoggedIn"); // 这里假设用户已登录

  // 如果用户未登录且尝试访问非登录页面，则重定向到登录页面
  if (!isUserLoggedIn && to.path !== "/login" && to.path !== "/front" && to.path !== "/") {
    ElementUI.Message.warning("请先登录");
    next("/login");
  } else {
    // 否则，继续路由导航
    next();
  }
});

import { postKeyValueRequest } from "./utils/api";
import { postRequest } from "./utils/api";
import { putRequest } from "./utils/api";
import { getRequest } from "./utils/api";
import { deleteRequest } from "./utils/api";

Vue.prototype.postKeyValueRequest = postKeyValueRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;

// 引入富文本组件
import QuillEditor from "vue-quill-editor";
// 引入富文本组件样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(QuillEditor);
// 引入富文本组件

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
